.showcase__section{
    background-color: #333;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 50px;
}


.showcase__section p{
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;

}



@media (min-width: 1500px) {
    .showcase__section p {
        font-size: 2.8vw;  /* Adjust based on viewport width */
        line-height: 3vw;
    }
  }
  
  /* Responsive styles for smaller screens (between 1440px and 1024px) */
  @media (max-width: 1440px) and (min-width: 1025px) {
    .showcase__section p {
        font-size: 36px;  /* Slightly smaller for tablets and smaller desktops */
        line-height: 42px;
    }
  }
  
  /* Responsive styles for small screens (1024px down to 700px) */
  @media (max-width: 1024px) and (min-width: 701px) {
    .showcase__section p {
        font-size: 32px;  /* Even smaller for tablets and small screens */
        line-height: 38px;
    }
  }
  
  /* Responsive styles for very small screens (below 700px) */
  @media (max-width: 700px) {
    .showcase__section p {
        font-size: 28px;  /* For mobile screens */
        line-height: 34px;
    }
  }
  
.showcase_images_section{
display:  flex;
width: 100%;
justify-content: space-between;
margin-bottom: 15px;
}


.showcase_image{
    height: auto;
    max-height: 400px;
    border-radius: 15px;
    background-color: black;
}
.showcase_first_image{
    width: 22%;

}
.showcase_second_image{
    width: 33%; 
}
.showcase_third_image{
    width: 42%; 
}


@media screen and (max-width: 600px) {

    .showcase__section{
        width: calc(90%-1.5rem);
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .showcase_image{
        height: 100px;border-radius: 12px;
    }

    .showcase__section p{
        font-family: Inter;
        font-size: 38px;
        font-weight: 600;
        text-align: left;
        
        }
    
}


@media (min-width: 601px) and (max-width:900px) {

    .showcase_image{
        height: 180px;border-radius: 12px;
    }
}