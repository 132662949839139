.benefits__section{



    width: calc(100%-6rem);
    display: flex;
    padding-left: 3rem;
    padding-right: 3rem;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 50px;

}

.benefits__section__content{
    display: flex;
    flex-direction: column;
}

.coming-soon-container{
    height: auto;
    align-items: center;
    justify-content: center;
    display: flex;
}


.benefits__section__left{
width: 50%;
height: 556px;
padding-right: 10%;  display: flex;
flex-direction: column;
justify-content: space-between;
}


.benefits__section__left__title{

    font-family: Inter;
font-size: 48px;
font-weight: 600;
line-height: 70px;
text-align: left;
margin: 0;

}

.coming-soon{
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 70px;
    text-align: center;
    margin: 0;

}

.benefits__section__left__subtitle{
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;
     
}

.benefits__section__left__content{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #A4A6A7;

}

.benefits__section__right{
width: 50%;
height: 556px;
border-radius: 12px;


}


.benefits__section__image{

    display: none;

    height: 350px;
    width: auto;
}
.benefits__section__left__buttons{
    width: 90%;
    height: 70px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
}

.benefits__section__left__button_enable{
    width: 30%;
    height: 40px;   border-radius: 50px;
    background-color: white;
    display: flex;
  justify-content: center; 
  align-items: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  transition: background-color 0.5s ease, color 0.5s ease;cursor: pointer;
}

.benefits__section__left__button_disable{
    width: 30%;
    height: 40px;   border-radius: 50px;
    cursor: pointer;
    display: flex;
  justify-content: center; 
  align-items: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  transition: background-color 0.5s ease, color 0.5s ease;
}

@media screen and (max-width:1000px)  and (min-width:800px) {

    .benefits__section{
        align-items: center;

    }
    .benefits__section__right{

        width: 40%;
        height: 400px;
    }
    .benefits__section__left{
        height: auto;
        width: 60%;
    }
    
   
}

@media screen and (max-width:650px)  {
    .benefits__section__left {
        padding-right: 0;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .benefits__section__content_main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .benefits__section__left__content{
        width: 90%;

        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .benefits__section__content{
        display: flex;
        flex-direction: column-reverse;

        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .benefits__section {
        width: 100%;

        display: flex;
        justify-content: center; /* Center children horizontally */
        align-items: center;
        padding: 20px 0;
        margin: 50px auto; /* Center the container within its parent */
    }

    .benefits__section__image{

        width: 90%;
    }
    .benefits__section__left__subtitle{

    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    }

    .benefits__section__left__title{

        font-family: Inter;
    font-size: 38px;
    font-weight: 600;
    line-height: 70px;
    text-align: center;
    margin: 0;
    
    }
    
    }
    
@media screen and (max-width:800px)  {

    .benefits__section__image{
        display: block;
    }

    .benefits__section{
        align-items: center;

    }
    .benefits__section__right{


        width: 0;

        display: none;
    }
    .benefits__section__left {
        padding-right: 0;
        align-items: center;
        width: 100%;
        height: auto;
        justify-content: center;
    }
    
    .benefits__section__left {
        padding-right: 0;
        align-items: center;
        width: 100%;
        height: auto;
        justify-content: center;
        display: flex; /* Ensure flexbox is applied */
        flex-direction: column; /* Ensures children stack vertically */
    }
    .benefits__section__image{

        width: 90%;
    }

    .benefits__section__content{

    }
    
   
}

