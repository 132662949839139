/* Main Section */
.homeMainSection {
    padding-left: 5rem;
    display: flex;
    padding-bottom: 30px;
    max-height: 700px;
    min-height: 450px;
    height: 40vw;
    justify-content: left;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/src/assets/homepage/homeBackgroundImage.svg');
}





.getStartedBox__button {
    width: 163px;
    border-radius: 28px;
    background-color: #E43A19;
    cursor: pointer;
}

.getStartedBox__button p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

/* Title Style */
.home-getStartedBox__title {
    font-family: Inter;
    font-size: 4vw;
    font-weight: bold;
    text-align: left;
    margin: 0;
}



@media (min-width: 1920px) {
    .home-getStartedBox__title {
        font-size: 4.5vw;
        
    }
}

@media (min-width: 1600px) {
    .home-getStartedBox__title {
        font-size: 4.5vw;
    }
}

@media (max-width: 1024px) {
    .home-getStartedBox__title {
        font-size: 4.5vw;
    }
}

/* Subtitle Style */
.home-getStartedBox__subtitle {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
}

@media (min-width: 1441px) {
    .home-getStartedBox__subtitle {
        font-size: 1.2vw;

    }
}

@media (max-width: 1439px) {
    .home-getStartedBox__subtitle {
        font-size: 15px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media (max-width: 1024px) {
    .home-getStartedBox__subtitle {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}




/* Responsive Styles */
@media screen and (max-width: 600px) {
    .homeMainSection {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        /* display: flex;
        flex-direction: column;
        margin-bottom: 50px; */
    }

    
    .getStartedBox {
        width: calc(100% - 10%);
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 0;
        padding-bottom: 0;
    }

    .home-getStartedBox__title {
        font-family: Inter;
        font-size: 32px;
        font-weight: bold;
        text-align: left;
        margin: 0;
    }

    .home-getStartedBox__subtitle {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
    }
}
