.keyFeatureComponent {
    width: calc(100% - 10rem);
    margin: 50px 5rem;
}

.keyFeature__previewImage {
    width: 100%;
    object-fit: fill; /* 'cover' for responsive image handling */
    height: auto;

 max-height: 80vh;
    border-radius: 12px;
}


@media (min-width: 1600px) {
    .KeyFeatureComponent__title{
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .KeyFeatureComponent__title {
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}


@media screen and (max-width:700px) {

    .KeyFeatureComponent__title {
        font-family: Inter;
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        text-align: left;
        
    }
}

.keyfeatures {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Enables wrapping for better responsiveness */
}

.keyfeaturesCard {
    border-top: 4px solid; /* Set the border thickness */
    border-image: linear-gradient(to right, #E43A19 70%, white 30%); /* Gradient for the top border */
    border-image-slice: 1;
    
    padding: 10px 20px 10px 0px;
    width: 30%;
    height: auto;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;

    transition: transform 0.3s ease;
}


@media (min-width: 1501px) {
    .keyfeaturesCard {
        border-top: calc(0.2vw) solid red;  
        padding: calc(1vw) calc(1.5vw) calc(0.7vw) 0;  /* Responsive padding using vw */
        width: 30%; 
       
    }
}

@media (min-width :1000px )  and (max-width:1250px) {

    .keyfeaturesCard {

        width: 25%;
    }

    
}
.keyfeaturesCard:hover {
    transform: scale(1.02);
}
.keyfeaturesCard__title{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color:white;
    
    margin: 0;
}

@media (min-width: 1441px) {
 
    .keyfeaturesCard__title{
        font-size: 1.4vw; 
        
    }
}




.keyfeaturesCard__description {
    color: #A4A6A7;
    font-family: Inter;
  font-size: 1vw; 
font-weight: 400;
line-height: 21.6px;
text-align: left;
color: #A4A6A7;
/* margin: 0; */

}

@media (min-width: 1441px) {
    .keyfeaturesCard__description {
        font-size: 1vw; 
        line-height: 1.44vw;  
    }
}

/* Large screens (above 1440px) */
@media (min-width: 1441px) {
    .keyfeaturesCard__description {
        font-size: 1vw; 
        line-height: 1.44vw; 
    }
}

@media (min-width: 700px) and (max-width: 1024px) {
    .keyfeaturesCard__description {
        font-size: 14px; 
        line-height: 20.4px;
    }
}
 

@media screen and (max-width: 900px) {
    .keyFeatureComponent {
        width: 90vw;
        margin: 10px 5vw;
    }

    .keyfeatures {
        flex-direction: column;
    }

    .keyfeaturesCard {
        width: 90vw;
        margin-top: 12px;
        height: auto;
        padding: 10px 0;
        background-color: black;
        
    }

    .keyfeaturesCard__title{
        font-size: 18px;
        color: white;
        padding-left: 10px;
        margin: 0;
        font-weight: 600;
    }
    .keyfeaturesCard__description{
        padding-left: 10px;
        font-size: 14px;

       
    }

   
}
