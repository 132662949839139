/* Container for the footer */
.footer-container {
    width: calc(100% - 6rem);
    margin: 2rem 3rem;
}

/* Main footer styling */
.footer-main {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #898989;
    padding-bottom: 1rem;
}

/* Styling for footer logo */
.footer-logo {
    height: 50px;
    width: auto;
    max-width: 100px;
    object-fit: contain;
}

/* Layout for the left section of the footer */
.footer-left {
    width: 50%;
}

/* Layout for the right section of the footer */
.footer-right {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}

/* Title and description styling for footer details */
.footer-detail-title {
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    
}

/* Large screens (1501px and above) */
@media (min-width: 1801px) {
    .footer-detail-title {
        font-size: 0.7vw ; /* Responsive font size using vw for large screens */
       
    }
}

/* Medium screens (between 1024px and 1500px) */
@media (max-width: 1500px) and (min-width: 1024px) {
    .footer-detail-title{
        font-size: 16px; /* Default font size */
        line-height: 19.36px;
    }
}

/* Small screens (below 1024px) */
@media (max-width: 1023px) {
    .footer-detail-title {
        font-size: 15px; /* Slightly smaller font size for small screens */
        line-height: 18px;
    }
}

/* Extra small screens (below 600px) */
@media (max-width: 600px) {
    .footer-detail-title {
        font-size: 14px; /* Even smaller for mobile */
        line-height: 17px;
    }
}

.footer-detail-description {
    color: #898989;
    text-decoration: none; 
    margin-top: 5px;
    font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;

}

@media (min-width: 1501px) {
    .element {
        font-size: 0.6vw; /* Responsive font size for large screens */
       
    }
}

/* Medium screens (between 1024px and 1500px) */
@media (max-width: 1500px) and (min-width: 1024px) {
    .element {
        font-size: 14px; /* Fixed font size for medium screens */
        line-height: 16.94px;
    }
}

/* Small screens (below 1024px) */
@media (max-width: 1023px) {
    .element {
        font-size: 13px; /* Slightly smaller for small screens */
        line-height: 16px;
    }
}

/* Extra small screens (below 600px) */
@media (max-width: 600px) {
    .element {
        font-size: 12px; /* Smaller size for mobile devices */
        line-height: 15px;
    }
}

/* Quick links section */
.quick-links {
    display: flex;
    flex-direction: column;
    align-items: start;
    
}

.quick-links a {
    color: #898989;
    text-decoration: none;
}

.quick-links a:hover {
    color: white;
}

/* Footer terms section styling */
.footer-terms {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

/* Terms section styling for left and right sides */
.footer-terms-left {
    display: flex;
    align-items: center;
}

.footer-terms-description {
    color: #898989;
    font-size: 13px;
}

/* Terms links */
.footer-terms-right {
    display: flex;
    width: 40%;
    justify-content: space-evenly;
}

.footer-terms-link {
    color: #898989;
    font-size: 13px;
    text-decoration: underline;
}

.footer-terms-link:hover {
    color: white;
}

/* Social link icon styling */
.link-icon {
    margin-right: 5px;
}



@media screen and (max-width:700px) {
    .footer-container {
        width: 90%;
        margin: 2rem auto; /* Centers the container horizontally with equal margins */
        display: flex; /* Enable flexbox layout */
        justify-content: center; /* Center children horizontally */
        align-items: center; /* Center children vertically if needed */
        flex-direction: column;
    }
    .footer-terms{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }.footer-terms-right{
        width: 100%;
    }

    .footer-main{
        flex-direction: column;
        width: 100%;
    }.footer-right{

        width: 100%;
        justify-content: space-between;
    }
}