

.benfitsSection{
    width: calc(100%-6rem);
  
    margin: 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.benefitIcon{

    width: 20px;
    height: 20px;

    fill: #E33B1B;
}

@media (min-width: 1441px) {
    .benefitIcon {
        width: calc(1.39vw);  /* Responsive width using viewport width */
        height: calc(1.39vw);  /* Responsive height using viewport width */
    }
}
.benfitsSection__title{


    font-family: Inter;
font-size: 48px;
font-weight: 600;
line-height: 70px;
text-align: center;

    color: white;
}

@media (min-width: 1600px) {
    .benfitsSection__title{
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .benfitsSection__title {
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}




.benefits{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.benefitsCard{

    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 27%;
    height: auto;
    justify-content: center;
    align-items: start;
    display: flex;
    flex-direction: column;
    background-color: #171717;
    border-radius: 12px;

}

@media (min-width: 1441px) {
    .benefitsCard{
        padding-left: calc(2vw);  /* Responsive left padding */
        padding-right: calc(2vw);  /* Responsive right padding */
        padding-top: calc(1.5vw);  /* Responsive top padding */
        padding-bottom: calc(1.5vw);  /* Responsive bottom padding */
    }
}
.benefitTitle{

    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
  
    text-align: left;
    
    color: white;

}
@media (min-width: 1441px) {
 
.benefitTitle{
        font-size: 1.4vw; 
        
    }
}



.benefitDescription{
    color: #A4A6A7;
    font-family: Inter;
  font-size: 1vw; 
font-weight: 400;
line-height: 21.6px;
text-align: left;
color: #A4A6A7;
margin: 0;

}

@media (min-width: 1441px) {
    .benefitDescription{
        font-size: 1vw; 
        line-height: 1.44vw;  
    }
}

/* Large screens (above 1440px) */
@media (min-width: 1441px) {
    .benefitDescription{
        font-size: 1vw; 
        line-height: 1.44vw; 
    }
}

@media (min-width: 700px) and (max-width: 1024px) {
    .benefitDescription{
        font-size: 14px; 
        line-height: 20.4px;
    }
}
 
    
    

    

    @media screen and (max-width:700px) {

        .benfitsSection__title{
            font-family: Inter;
            font-size: 32px;
            font-weight: 500;
            line-height: 38.4px;
            text-align: left;
            
        }
        .benefitDescription{  
            font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.8px;
text-align: left;

        }
    }

@media screen and (max-width:900px) {

    
    
    .benefits{
        width: 100%;
        
 justify-content: center;
 align-items: center;
        flex-direction: column;
    }

    .benefitsCard{
        width: 100%;
        margin-bottom: 20px;
    }
.benfitsSection{
    width: 80vw;
    margin: 0 auto;
}
}


@media screen and (min-width:901px) and (max-width:1185px) {

    .benefitsCard{

 
    width: 25%;
    height: 235px;
   

}

}
