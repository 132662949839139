


.forconsumershowcase{

    width: calc(100% - 6rem);
    margin: 0 3rem;
 object-fit: fill;
 height: auto;
 max-height: 80vh;
 border-radius: 12px;

 @media screen and (max-width:600px) {
    margin: 0 ;
    width: 100%;
    border-radius: 0;
    object-fit: contain;
    height: auto;
 }
}