
.appButtons{
    display: flex;
    
    gap: 0.5vw;
}
.appButton{
    width: 152.42px;
    height: 51.31px;

    

}

@media (min-width: 1441px) {
    .appButton {
        width: 10.5vw;  
        height: 3.5vw;   
       
    }
}

@media screen and (max-width:600px) {

    .appButtons{
        width: 100%;
        justify-content: space-evenly;    }
}