.terms-and-conditions {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: white;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;

    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .terms-and-conditions h1 {
    font-size: 32px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-and-conditions h2 {
    font-size: 24px;
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-and-conditions p {
    margin-bottom: 10px;
  }
  
  .terms-and-conditions ul {
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .terms-and-conditions a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .terms-and-conditions a:hover {
    text-decoration: underline;
  }
  