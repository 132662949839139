
.forConsumerMainSection{

        width: calc(100% - 6rem);        
        margin: 0 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 50px;

 
}

.forConsumerMainSection__header{
    display: flex;
}

.forConsumerMainSection__left{
    width: 50%;

   

    padding-right: 40px;    
}
.forConsumerMainSection__right  {

    width: 50%;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  
}

.forConsumerMainSection__right p{
    margin-top: 35px;
    margin-bottom: 35px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: left;
    color: #A4A6A7;
}

@media (min-width: 1441px) {
    .forConsumerMainSection__right p{
        font-size: 1vw; 
        line-height: 1.44vw;  
    }
}

@media (max-width: 1439px) {
    .forConsumerMainSection__right p{
        font-size: 15px;  
        line-height: 18px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}


@media (max-width: 1024px) {
    .forConsumerMainSection__right p{
        font-size: 13px;  
        line-height: 17px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.getStartedBox__button p{
    color: white;   
    font-weight: 500px;   text-align: center;  
    cursor: pointer;
   
   }

   @media (min-width: 1441px) {
  .getStartedBox__button p {
        font-size: 1vw;  /* Scale font-size relative to viewport width */
        line-height: 1.44vw;  /* Adjust line height proportionally */
    }
}
   
   .getStartedBox__button{
    width: 150px;
    border-radius: 30px;
    background-color: red;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
 

}

@media (min-width: 1441px) {
    .getStartedBox__button {
        width: 10.4vw;   /* Scale button width relative to viewport width */
        height: 2.78vw;  
        border-radius: 2vw;  /* Adjust border-radius for larger screens */
    }
}



 /* title */
   .getStartedBox__title{
    font-family: Inter;
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    line-height: 48px;
    margin: 0;   
}



@media (min-width: 1920px) {
    .getStartedBox__title{
        font-size: 2.5vw;  
        line-height: 3vw;
    }
}

@media (min-width: 1600px) {
    .getStartedBox__title{
        font-size: 2.8vw; 
        line-height: 3.2vw; 
    }
}

@media (max-width: 1024px) {
    .getStartedBox__title{
        font-size: 3.8vw;
        line-height: 4vw;
    }
}


   @media screen and (max-width:700px) {

    .forConsumerMainSection{
        width: calc(100% - 40px); /* Subtracting 40px because the total horizontal margin is 20px on each side */
        margin: 25px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    

}

.getStartedBox__title{
    font-family: Inter;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    text-align: left;
    

}

.forConsumerMainSection__right p{
   
    font-size: 14px;
    margin-top: 0;
   
}

    .forConsumerMainSection__header{
        display: flex;
        flex-direction: column;
    }

    .forConsumerMainSection__right  {

        width: 100%;
        padding: 0;
      
    }
    .forConsumerMainSection__left {

        width: 100%;
        padding: 0;

        
       
      
    }
    
    
   }