
.forRoleComponent{


    border-radius: 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    width: 130px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;

}

@media (min-width: 1441px) {
    .forRoleComponent {
        padding-top: 0;
        padding-bottom: 0;
        width: 9.4vw;   /* Scale button width relative to viewport width */
        height: 2.78vw;  
        border-radius: 2vw;  /* Adjust border-radius for larger screens */
        font-size: 1vw;  /* Scale font-size relative to viewport width */
       
    }
}


@media screen and (max-width:900px) {

    .forRoleComponent {
        width: 150px;   
        height: 24px;  
       
       
       
    }
    
}

.dotRole{
    width: 15px;
    height:  15px;
    border-radius: 100px;
    background-color: black;
}