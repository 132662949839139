


.homeDigitalArt{
    width: calc(100%-10rem);
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 3rem;
    margin-bottom: 50px;background-color: #272727;
    margin-top: 50px;

}


.homeDigitalArt__header{

    width: 100%;
    display: flex;
    height: 400px;

    justify-content: space-evenly ;
}

.homeDigitalArt__header__left{
    display: flex;
    height: 400px;
    flex-direction: column;
}
.homeDigitalArt__header__right{
    display: flex;
    height: 350px;
    flex-direction: column-reverse;
}
.homeDigitalArt__header__image{
    border-radius: 15px;
    height: 186.11px;
    width: 258.11px;

}

.homeDigitalArt__header__title__section{
    position:absolute;
}

.homeDigitalArt__header__title{
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
    
    

    
}



@media (min-width: 1441px) {
    .homeDigitalArt__header__title {
        font-size: 2.8vw;  /* Adjust based on viewport width */
        line-height: 3vw;
        margin-bottom: 1vw;
    }
  }
  
  /* Responsive styles for smaller screens (between 1440px and 1024px) */
  @media (max-width: 1440px) and (min-width: 1025px) {
    .homeDigitalArt__header__title {
        font-size: 36px;  /* Slightly smaller for tablets and smaller desktops */
        line-height: 42px;
    }
  }
  
  /* Responsive styles for small screens (1024px down to 700px) */
  @media (max-width: 1024px) and (min-width: 701px) {
    .homeDigitalArt__header__title {
        font-size: 32px;  /* Even smaller for tablets and small screens */
        line-height: 38px;
    }
  }
  
  /* Responsive styles for very small screens (below 700px) */
  @media (max-width: 700px) {
    .homeDigitalArt__header__title {
        font-size: 28px;  
        line-height: 34px;
    }
  }
  


.homeDigitalArt__header__description{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    color:#A4A6A7;    
    
}

.enternow{
    width: 30%;
    margin-top: 18px;
    margin-bottom: 18px;
    background-color: red;
    border-radius: 24px;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;cursor: pointer;
    }


.homeDigitalArt__header__middle{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    height: 350px;
}

.contestimage__body{
    width: 100%;
    display: flex;
    align-items: center;
}

.contestimage{

    margin-left: 10%;
    width: 80%;
    margin-right: 10%;
}


.homeDigitalArt__header__images{
    height: 400px;
    width: 90%;

    margin-left: 5rem;
    margin-right: 5rem;
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width :1500px) {
    .homeDigitalArt{
        width: 100vw;
        padding-left: 0;
        padding-right: 0;
        
    }

    .homeDigitalArt__header__image{
        height: 100.11px;
        width: 149.11px;
    }

    .homeDigitalArt__header{
    width: 100%;
    }   
    .homeDigitalArt__header__right{
        height: 150px;
    }
    .homeDigitalArt__header__title__section{

        padding-top: 80px;
    }

  .homeDigitalArt__header{

    justify-content: space-around;
  }

  
    
}


@media screen and (max-width:600px) {   .homeDigitalArt{
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
    
}
    .homeDigitalArt__header__title{
        font-size: 24px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .homeDigitalArt__header__images{
        margin-left: 0;
        margin-right: 0;
    }

}

