.streamArt-section {
    margin: 6rem 5rem;
    display: flex;
    border-radius: 12px;
    background-color: #272727;
    align-items: center; /* This aligns the left and right sections vertically */
 
}

.streamArt-section__right {
    width: 55%;
    height: auto;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.streamArt-section__left {
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding:1% 5% ;
    
}

.streamArt-section-subtitle{

    font-size: 18px;
    color: #A4A6A7;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tv-brands-container{

    display: flex;
    gap: 0 20px;
    flex-wrap: wrap;
}
.tv-logos{
    height: 70px;
    width: 70px;
    
}
.streamArt-section__title_1 {
    color: #6F6F6F;
    font-family: Inter;
    font-size: 44px;
    font-weight: 600;
    line-height: 62.93px;
    text-align: left;
    
}
.streamArt-section__title_2 {
    margin: 0;
    color: white;
    font-family: Inter;
    font-size: 44px;
    font-weight: 600;
    text-align: left;
    
}


@media (min-width: 1600px) {
    .streamArt-section__title_1 ,.streamArt-section__title_2{
        font-size: 2.8vw;  
        line-height: 3.2vw; 
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .streamArt-section__title_1 ,.streamArt-section__title_2{
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}
@media (max-width: 1439px) {
    .streamArt-section__title_1 ,.streamArt-section__title_2{
   
            font-size: 30px;  /* Adjusted font size for tablets */
            line-height: 48px;  /* Adjusted line-height */
        
    }
}

@media (max-width: 900px) {
    .streamArt-section__title_1 ,.streamArt-section__title_2{
        font-size: 38px;  /* Reduced font size */
        line-height: 48px;  /* Adjusted line-height */
    }
}



.StreamArt__appButtons{

    gap: 0.5vw;
    display: flex;
}




.StreamArt__appButton{
    width: 152.42px;
    height: 51.31px;

    
}


@media (min-width: 1441px) {
    .StreamArt__appButton{
        width: 10.5vw;   
        height: 3.5vw;   


    }
}



@media screen and (min-width :901px) and (max-width:1215px) {

    .streamArt-section__right{
        width: 45%;
        margin-right: 5%;
        border-radius: 12px;
    }
    
}
@media screen and (max-width:900px) {
    
    .streamArt-section {
        width: 90vw;
        margin: 50px 5vw;
       flex-direction: column-reverse;
       padding-bottom: 30px;
    }

    .streamArt-section__right {
        border-radius: 12px;
    margin-top: 30px;
    width: 80%;
    height: auto;
}
    .streamArt-section__left {
        padding-top: 20px;
        width: auto;
        margin:0 auto;
    
        
    }
    
    
    .StreamArt__appButton{
        width: 150px;
        padding-left: 10px;
        height: auto;
    }
    
}