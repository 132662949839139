h1{
    color :white
}

h2{
    color:white;
}

p{
    color: white;
}