

.pricing-section{

    width: calc(100% - 6rem);

margin-left: 3rem;
margin-right: 3rem;
    display: flex;
    margin-bottom: 5rem;
    margin-top: 50px;
}

.pricing-section__left{
    background-color:  #272727;
    width: 100%;

    height: auto;

   
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 50px 0;

    padding-left: 3vw;
    padding-right: 3vw;


}



.pricing-section__coming-soon{

    width: 100%;
    
    margin: 50px 0;
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 58.09px;
    text-align: center;
}

@media (min-width: 1600px) {
    .pricing-section__coming-soon{
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}



/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .pricing-section__coming-soon{
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}
@media (max-width: 1439px) {
    .pricing-section__coming-soon{
        font-size: 2.5vw;  /* Adjusted for mid-size screens */
        line-height: 2.9vw;
    }
}

@media screen and (max-width:700px) {

    .pricing-section__coming-soon{
        font-family: Inter;
        font-size: 25px;
        font-weight: 500;
        line-height: 38.4px;
        text-align: center;
        
    }
}

.pricing-section__left__title{
    font-family: Inter;
font-size: 48px;
font-weight: 600;
line-height: 58.09px;
text-align: left;

}

  @media (min-width: 1600px) {
    .pricing-section__left__title{
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .pricing-section__left__title {
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}





.pricing-section__left__description  {
    margin-top: 35px;
    margin-bottom: 35px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #A4A6A7;
}

@media (min-width: 1441px) {
    .pricing-section__left__description  {
        font-size: 1vw; 
        line-height: 1.44vw;  
    }
}

@media (max-width: 1439px) {
    .pricing-section__left__description {
        font-size: 15px;  
        line-height: 18px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}


@media (max-width: 1024px) {
    .pricing-section__left__description  {
        font-size: 13px;  
        line-height: 17px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}


@media screen and (max-width:700px) {

    .pricing-section__left__title{
        font-family: Inter;
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        text-align: center;
        
    }

   
}


.pricing-section__left__content{
    margin: 20px 0;
    border-bottom: 1px dashed #A4A6A7 ;
    
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;

}
.pricing-section__perks{
    margin: 20px 40px;

    display: flex;
    flex-wrap: wrap;

}

.getStartedTitle__appButtons{

    display: flex;gap: 10px;
}
.getStartedTitle__appButton{
    width: 100px;
    height: auto;
}
/* .perk-icon,  */

.perk{
    width: 25%;
    min-width: 150px;
    /* max-width: 150px; */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;gap: 10px;
    color: white;

}

.perkperk-icon{ color: #fff;}
.pricing-section__right{
    height: auto;
    width: 45%;

    padding-top: 50px;
    padding-bottom: 50px;
    
    background-color:  white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    justify-content: center;
    display: flex;
    flex-direction: column;

align-items: center;}


.pricing-section__right__plan-title{

    border-radius: 100px;
    background-color: #E0E0E0;
    color: #000;
    padding: 15px;
    font-weight: 600;
}
.pricing-section__right__plan-discount{

    border-radius: 100px;
    background-color: red;
    color: white;
    padding: 15px;

    font-weight: 600;
}
.amount{

    font-family: Montserrat;
    font-size: 35px;
}
.getStarted{
    align-items: center;
}
.getStartedTitle{

    font-size: 25px;
    color: black;
    align-items: center;
    text-align: center;cursor: pointer;
}
.pricing-section__right__plan-price{

    border-radius: 100px;
   
    color: red;
    padding: 15px;

    font-weight: 600;
}




@media screen and (max-width:601px) {
    
    .pricing-section{

        width: 90vw;
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
        margin: 50px auto;
        justify-content: center;
        align-items: center;
       
    }

    .pricing-section__left__content{

        padding-left: 5vw;
        padding-right: 5vw;
        width: 80%;
    }

    .pricing-section__left{
        background-color:  #272727;
        width: auto;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      
        padding: 10px 5vw;
    
    
    }

    

    .pricing-section__right{
        height: auto;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0px;
        padding: 20px 0; 
     }
}

@media screen and (min-width:601px)  and (max-width:850px){
    .pricing-section{

        width:calc(100% - 6rem); 
        margin: 10px auto;
       
        flex-direction: column;

        justify-content: center;
        align-items: center;
        
        
       

    }
    .pricing-section__left{
        background-color:  #272727;
        width:calc(100% - 6rem); 
        
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 20px 0;    
        
    
    
    }

    .pricing-section__left__content,.pricing-section__left__description  {
        margin-left: 5vw; 
        margin-right: 5vw; 
        width: auto;
    }
    .pricing-section__right{
        height: auto;
        width:calc(100% - 6rem); 
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0px;
        padding: 20px 0; 

     }
}