.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 0.5rem 5rem;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative; /* Ensure dropdown positions correctly */
}

.logo {
  display: flex;
  align-items: center;
  color: #fff;
}

.logo img {
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
}
@media (min-width:  1701px) {
  .logo img {
      height: calc(1.1vw + 16px); /* Responsive height for larger screens */
  }
}
.nav {
  display: flex;
  gap: 1.2rem;
  position: relative; /* Ensure dropdown menu is positioned relative to the nav */
}

.nav a {

  font-family: Inter;
font-size: 18px;
font-weight: 400;
line-height: 21.6px;
cursor: pointer;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative; /* Ensure dropdown menu is positioned relative to the link */
}

@media (min-width:  1701px) {
  .nav a {
    font-size: 0.8vw; /* Responsive height for larger screens */
  }
}


.nav a:hover {
  color: #d35400;
}

.navbar-items {
  display: flex;
  align-items: center; /* Vertically centers items */
  text-decoration: none; /* Remove underline from link */
  color: white;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;

  

  width: 100%;
  justify-content: center;
  position: relative; /* For positioning dropdown */
  cursor: pointer; /* Make it clickable */
 

}

.item{
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
}

@media (min-width:  1701px) {
  .item {
    font-size: 0.8vw; /* Responsive height for larger screens */
  }
}

.navbar-items:hover {
  color: #d35400;
}


.navbar-items-icon {
  margin-left: 4px; /* Space between text and icon */
  width: 15px;
  height: 15px;
}

.dropdown-menu {
  display: none; /* Hide by default */
  position: absolute;
  top: 150%; /* Position below the navbar-items */
  left: 0;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px;
  width: 200px; /* Adjust width as needed */
  z-index: 1000;
}

.dropdown-menu.open {
  display: block; /* Show when open */
}

.dropdown-item {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block; /* Ensure items are block-level for vertical stacking */
  transition: background-color 0.3s ease;

  display: flex;
  justify-content: space-between;
  border-bottom: 0.1px rgb(84, 84, 84) solid;
}


.dropdown-item:hover {
color: #d35400;
}

.auth-buttons {
  display: flex;
  gap: 0.5rem;
}

.sign-up {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.2px;
text-decoration: none;

}

@media (min-width: 1701px) {
  .sign-up {
    font-size: 0.8vw; /* Responsive height for larger screens */
  }
}

.sign-up:hover {
  color: #d35400;
}

.navbar-items {
  display: flex;
  align-items: center; /* Vertically centers items */
  text-decoration: none; /* Remove underline from link */
  color: white;
  font-size: 16px;
  width: 100%;
  justify-content: center;
  position: relative; /* For positioning dropdown */
  cursor: pointer; /* Make it clickable */
 

}
.login {
  background-color: #E43A19;
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100px;
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19.2px;
text-decoration: none;

}

@media (min-width: 1501px) {
  .login {
      width: 120px; /* Increase width */
      font-size: 18px; /* Increase font size */
      line-height: 22px; /* Adjust line height */
  }
}

/* Medium screens (between 1024px and 1440px) */
@media (max-width: 1501) and (min-width: 1024px) {
  .login {
      /* padding: 0.6rem 1.1rem; */
      width: 110px;
      font-size: 17px;
      line-height: 21px;
  }
}

/* Small screens (below 1024px) */
@media (max-width: 1023px) {
  .login {
      padding: 0.5rem 1rem;
      width: 100px;
      font-size: 16px; /* Smaller size for better fit */
      line-height: 19.2px;
  }
}
.login:hover {
  background-color: #e67e22;
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 0.25rem;
  cursor: pointer;
  z-index: 1100; /* Higher z-index to ensure it stays on top */
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: transform 0.3s ease, opacity 0.3s ease;
}


.responsive-item{
  display: none;
}
@media (max-width: 950px) {
  .header {
    padding: 0.5rem 1rem;
    height: 50px;
  }

  .nav {
    display: none;
    flex-direction: column;
    background-color: #333;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1000;
    padding: 1rem 0; /* Padding for the dropdown */
  }


  .responsive-item{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

  }
  .navbar-items {
   
   display: none;
      padding: 1rem 0; /* Add padding to links for better spacing */
    border-bottom: 1px solid #444; /* Optional: adds a dividing line between links */
  }
  .nav.open {
    display: flex;
  }

  .nav a {
    padding: 1rem 0; /* Add padding to links for better spacing */
    border-bottom: 1px solid #444; /* Optional: adds a dividing line between links */
  
  }

  .auth-buttons {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .hamburger.open .line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.open .line:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .auth-buttons {
    flex-direction: column;
  }
}

.close-icon {
  font-size: 35px;
  color: #fff;
}
