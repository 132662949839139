.faq {
    width: calc(100% - 10rem);        
    margin: 5rem 5rem;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    
  }
  .faq__title{
    
  
    font-family: Inter;
font-size: 48px;
font-weight: 600;
line-height: 70px;
text-align: left;

 
  }

  @media (min-width: 1600px) {
    .faq__title{
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .faq__title {
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}

@media screen and (max-width:700px) {

  .faq__title {
      font-family: Inter;
      font-size: 32px;
      font-weight: 500;
      line-height: 38.4px;
      text-align: center;
      
  }
}

  .faq h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .faq__list {

    border-radius: 8px;
    padding: 10px;
    width: 55%;
  }
  
  .faq__item {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    
  }
  
  .faq__item:last-child {
    border-bottom: none;
  }

  

  .faq__subanswer{

    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    
    color: #A4A6A7;
  }
  @media (min-width: 1441px) {
    .faq__subanswer {
        font-size: 0.8vw;  /* Scale font size relative to viewport width */
        line-height: 1.68vw; /* Maintain proportional line height */
    }
}
  
  .faq__question {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    align-items: start; color: white;

  }
  @media (min-width: 1441px) {
    .faq__question {
        font-size: 1.1vw;  /* Scale font size relative to viewport width */
        line-height: 1.68vw; /* Maintain proportional line height */
    }
}
  
  .faq__question.active {
    font-weight: bold;
    color: white;
  }
  
  .faq__answer {
    margin-top: 10px;
    padding-left: 10px;
    color: #A4A6A7;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    
  }
  @media (min-width: 1441px) {
    .faq__answer {
        font-size: 0.8vw;  
        line-height: 1.68vw; 
    }
}
  
  
  .faq__question span {
    font-size: 24px;
    font-weight: bold;
  }
  
  @media screen and (max-width: 768px) {
    .faq {
      width: calc(100% - 40px); /* Subtracting 40px because the total horizontal margin is 20px on each side */
      margin: 25px 20px;
      flex-direction: column;
    }
    .faq__list {

   margin: 0;
   padding: 0;
      width: 100%;
    }
    
    .faq__question {
      font-size: 16px;
    }
  
    .faq__answer {
      font-size: 14px;
    }
  }
  