 .features__section
 { width: calc(100%-10rem);
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 5px;
    padding-bottom: 5px;
margin-bottom: 50px;
margin-top: 50px;



}

.features{
    display: flex;
width: 100%;
justify-content: space-evenly;  height: 500px;
margin-bottom: 50px;

}
    
        .features__section__title{


            font-family: Inter;
        font-size: 48px;
        font-weight: 600;
        /* line-height: 70px; */
        text-align: left;
        
            color: white;
        }
        
        @media (min-width: 1500px) {
            .features__section__title{
                font-size: 2.8vw;  /* Relative to viewport width */
                line-height: 3.2vw; /* Maintain proportional line height */
            }
        }
        
        /* Responsive styles for even larger screens (min-width 1920px) */
        @media (min-width: 1920px) {
            .features__section__title{
                font-size: 2.5vw;  /* Fine-tuned for very large screens */
                line-height: 3vw;
            }
        }
        @media screen and (max-width:700px) {
            .features__section__title{
            font-family: Inter;
            font-size: 32px;
            font-weight: 500;
            line-height: 38.4px;
            text-align: center;
            }
        }
        
    
    
    .features__left{
        width: 50%;
        height: 569px;
     
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly; 
     
    }
    .features__right {
        height: auto;
        width: 50%; /* For 1440px screens */
      display: flex;
      justify-content: center;
      align-items: center;
    }

    
    
    
.features__right_image{
    width: 85%;
    height: auto;
    border-radius: 15px;
    background-color: #CECAD8;
}
   

@media screen and (min-width:1600px){
    .features__right_image{
        width: 85%;

        max-width: 600px;
        height: auto;
        border-radius: 15px;
        background-color: #CECAD8;
    }
     
}
    


    .feature__card{
        width: 90%;
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        align-items: start;

        
    }

.feature__card__enable{

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    background-color: #171717;

    border-bottom: 2px solid; 
    border-image: linear-gradient(to right, #E43A19 70%, white 30%); /* Gradient for the top border */
    border-image-slice: 1;
    
}
.feature__card__disable{
    background-color: black;cursor: pointer;
}
.feature__icon__disable{
    padding-right: 10px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    text-align: left;color: #A4A6A7;  
    fill: #A4A6A7;  
}
.feature__icon__enable{
    padding-right: 10px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    text-align: left; color: #E43A19;

    fill: #E43A19;
}


.feature__title__disable{
    font-family: Montserrat;
font-size: 20px;
font-weight: 500;
line-height: 24.38px;
text-align: left;
color: #A4A6A7;

}


.feature__title__enable{

    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: white;

}
@media (min-width: 1600px) {
 
    .feature__title__enable{
        font-size: 1.2vw; 
        
    }
}



.feature__description__enable{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
padding-left: 20px;
    padding-top: 20px;
    
    text-align: left;
    color: #A4A6A7;
}



.responsive-feature-cards{
    display: none;
}


/* responsive feature cards */



@media screen and (max-width:900px) {

   
    .features__section {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        width: 100%;
        padding: 5px 0;
        margin: 50px 0;
    }
    
    .features{
        display: none;
    }

    .features__right_image{
        width: 90vw;
        height: auto;
    }
    .responsive-feature-cards{

        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;

   
    }

    .responsive-feature-cards{

        margin-top: 25px;

    }
    
    .responsive-feature-card__image{

        width: 90%;
        height: auto;
     
        border-radius: 8.44px;

    
        
        }
}

.responsive-feature-cards_content{
  
    display: flex;
margin-top: 10px;
    width: 90%;
    padding-bottom: 15px;
    align-items: start;
    justify-content: start;
    margin-top: 15px;
}




.responsive-feature-cards_content__description{

    color: #A4A6A7;
    font-size: 15px;
    font-size: 500;
    margin-top: 5px;
    margin-bottom: 5px;
    /* padding-right: 15px; */
    margin-left: 0;
    margin-right: 0;

}
.responsive-feature-cards_content__title{

    color: white;  
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    
    
    margin: 0;

   

}

.responsive-feature-cards_content__right{
    margin-left: 10px;
}

.responsive-feature-cards_content__left{
    fill: white;


    display: flex;
    font-size: 25px;
    justify-content: center;
    /* width: 20%; */
}