
.role-guide__section{

    width: calc(100% - 6rem);
    background-color:  black;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;  
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
    margin-top: 50px;

}

.role-guide__header{
    display: flex;
    width: 100%;
    justify-content: space-between;
   
    align-items: center    ;
}



.role-guide__header__title{
    font-family: Inter;
font-size: 48px;
font-weight: 600;
line-height: 70px;
text-align: center;

    color: white;
    margin-bottom: 10px;
}

@media (min-width: 1600px) {
    .role-guide__header__title{
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .role-guide__header__title{
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}

@media screen and (max-width:700px) {

    .role-guide__header__title{
        font-family: Inter;
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        text-align: center;
        
    }}



@media screen and (max-width:900px) {

    

    .role-stepguide__description{
    
        width: 80vw;
        font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.8px;
text-align: left;

        color: #A4A6A7;
        margin-top: 20px;
        
    }
}
.role-guide__header__button div{
    cursor:pointer;
}
.role-guide__header__button{

    width: 350px;
    height: 70px;
    border-radius: 50px;
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.role-guide__header__button_enable{
 
    width: 150px;
    height: 40px;   border-radius: 50px;
    background-color: white;
    display: flex;
  justify-content: center; 
  align-items: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.role-guide__header__button_disabled{
    width: 150px;
    height: 40px;  display: flex;
    justify-content: center; 
    align-items: center;
}
.stepguide__header{
    display: flex;
    align-items: center;

}



.stepguide__header__count{
    border-radius: 100px;
    background-color: #FF8E3C;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-size: 21.33px;
    font-weight: 800;
    line-height: 25.6px;
    text-align: left;
    
}


.stepguide__header__title{

    padding-left: 20px;
    color: #FF8E3C;
    font-family: Inter;
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
}



.stepguide{
    height: auto;
    width: 28vw; 
}

.stepguide__card{
    /* width: 100%; 
    height: auto; */

    height: 420px;
    width: 302px;
    border-radius: 12px;
    border: 2px solid; 
    size:cover;
}

.role-guide__body{
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
}




.stepguide{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.trybutton{
width: 25vw;
margin-top: 18px;
margin-bottom: 18px;
background-color: red;
border-radius: 30px;
height: 50px;
color: white;
display: flex;
justify-content: center;
align-items: center;
}




@media only screen and (max-width: 900px){

    .role-guide__section{

        margin-bottom: 1rem;
       
    }

    .stepguide__header{
        width: 80vw;
    }
    

    .role-stepguide__header__content{
        display: flex;
        flex-direction: column-reverse;
    }

    .role-guide__header{
        flex-direction: column;
    }
    .role-guide__body{

        display: block;
        width: 85%;
       
    }

    .stepguide__card{
        width: 80vw;
        height: auto;
        margin-top: 25px;
    }
    .stepguide__description{
        width: 80vw;
        margin-bottom: 25px;
    }
    .trybutton{
        margin-top: 20px;
        width: 90vw;
    }

    .role-guide__header__button{

        width: 90vw;
        padding-top: 15px;
        padding-bottom: 15px;
       
    }

    
}



@media only screen and (min-width:500px)  and (max-width: 899px){
    .stepguide__card{
        width: 80vw;
        height: auto;
        margin-top: 25px;
        align-items: center;
    }
}

.role-stepguide__header__title{
    font-family: Inter;
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
margin-left: 10px;

}
@media (min-width: 1600px) {
    .role-stepguide__header__title {
        font-size: 1vw; /* Increase font size for large screens */
        line-height: 28px;
    }
}




.role-stepguide__description {
    color: #A4A6A7;
    font-family: Inter;
    width: 80%;
  font-size: 1vw; 
font-weight: 400;
line-height: 21.6px;
text-align: left;
color: #A4A6A7;

}

@media (min-width: 1441px) {
    .role-stepguide__description {
        font-size: 1vw; 
        line-height: 1.44vw;  
    }
}

/* Large screens (above 1440px) */
@media (min-width: 1441px) {
    .role-stepguide__description {
        font-size: 1vw; 
        line-height: 1.44vw; 
    }
}

@media (min-width: 700px) and (max-width: 1024px) {
    .role-stepguide__description {
        
        font-size: 14px; 
        line-height: 20.4px;
    }
}
 


@media (min-width: 651px) and (max-width: 991.98px) {

    .role-guide__section{

      
        margin-top: 10px;
    
    }
   


   
   

    .role-stepguide__header__title{
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        
        
    }

  

    .stepguide__header__count{
        width: 30px;
        height: 30px;
    }

}

@media screen and (max-width:900px) {

    .role-guide__section {
        padding-left: 0;
        padding-right: 0;
        width: 90%;
        margin: 0 auto;
    }

    .stepguide{
        width: 90; 
    }


    .role-guide__header__title{
       margin-bottom: 0;
        
    }

    
    .role-stepguide__description{
    
        width: 60vw;
        font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.8px;
text-align: left;
margin-left: 60px;
margin-right: 0;
margin-top: 0;

        color: #A4A6A7;
        margin-top: 0;
        
    }
}