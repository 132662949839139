.testimonials__section {
    margin-left: 5rem;
    margin-right: 5rem;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
}


.testimonials__section__title {
    font-family: Inter;
font-size: 48px;
font-weight: 600;
line-height: 70px;
text-align: left;

    color: white;
}

@media (min-width: 1500px) {
    .testimonials__section__title {
        font-size: 2.8vw;  /* Relative to viewport width */
        line-height: 3.2vw; /* Maintain proportional line height */
    }
}

/* Responsive styles for even larger screens (min-width 1920px) */
@media (min-width: 1920px) {
    .testimonials__section__title {
        font-size: 2.5vw;  /* Fine-tuned for very large screens */
        line-height: 3vw;
    }
}
  
@media screen and (max-width:700px) {

    .testimonials__section__title {
        font-family: Inter;
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        text-align: center;
        
    }}

.testimonials__carousel {
    position: relative;
    overflow: hidden;
}

.testimonials-coming-soon{

    font-size: 3.12vw;
    color: black;
}

.testimonials__card {
    height: 478px;
    width: calc(100% - 6.5rem);
    background-color: #C2EEF4;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    transition: transform 0.5s ease-in-out;
}

@media (min-width: 1441px) {
    .testimonials__card {
        height: 450px; /* Adjust as needed */
    }
}


.testimonials__card__doublequote {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.testimonials__card__left {
    padding-left: 10px;
    padding-right: 10px;
}

.testimonials__card__title {
    font-size: 2rem;
    color: black;
}

.testimonials__card__description {
    font-size: 1.5rem;
    color: black;
}

.testimonials__card__time {
    font-size: 0.8rem;
    color: black;
}

.testimonials__card__image {
    width: 411px;
    border-radius: 12px;
    height: 390px;
}

/* Centering custom controls */
.custom-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

/* Custom arrows with CSS icons */
.custom-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-arrow {
color: white;
    border: none;
    cursor: pointer;
    outline: none;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-arrow--prev::before,
.custom-arrow--next::before {
    content: '';
    display: block;
    width: 0; 
    height: 0; 
    border-style: solid;
}



.custom-dots {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    list-style: none;
    margin-left: 20px;
    margin-right: 20px;
}

.custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: white;
    margin: 0 5px;
    cursor: pointer;
}

.custom-dot.active {
    background-color: #E43A19;
    width: 30px;
}


@media screen and (max-width:900px) {
    .testimonials__section {
        width: 90%;
        margin: 0 auto;
    padding-top: 5px;
  
    position: relative;
    display: flex;
    justify-content: end; /* Horizontally center children */
    align-items: center;     /* Vertically center children */
    flex-direction: column;  /* Stack children vertically if needed */
    }
    .testimonials__card {
        padding: 0;
        width: 90vw;
        height: 415px;
        margin: 0 auto;
        flex-direction: column-reverse;
        justify-content: space-evenly;
       
    }

    

    .testimonials__card__title {
        font-size:20px;
        color: black;
    }
    
    .testimonials__card__description {
        font-size: 12px;
        color: black;
    }

    .testimonials-coming-soon{

        font-size:20px;

    }
    .testimonials__card__image {
        width: calc(100% - 40px);
        border-radius: 12px;
        height: 200px;
    }
    .testimonials__card__doublequote{
        width: 50px;
        height: auto;

    }.testimonials__card__right{
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .testimonials__card__left{

        padding-left: 20px;
        padding-right: 20px;
    }

}