.container {
  width: 90%;
  margin: 0 auto;
  
  
}

#cards {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight));
  gap: var(--cardMargin);
  padding-bottom: calc(var(--cards) * var(--cardTopPadding));
  margin-bottom: var(--cardMargin);
}

.card {
  position: sticky;
  top: 0; /* Creates a 300px space between the top of the viewport and the sticky card */
  padding-top: calc(var(--1) * var(--cardTopPadding));
  
}

h2 {
  font-size: 2.5em;
}

.pixlmeBenefits__section {
  /* width: calc(100% - 6rem); */

  padding: 4rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: #272727;
  
}

.pixlmeBenefits__card {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 50px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  
}

.pixlmeBenefits__card__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pixlmeBenefits__card__left p {
  color: black;
}

.pixlmeBenefits__card__title {
  font-family: Inter;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: left;
  margin-bottom: 25px;
  margin-top: 0;
}

.pixlmeBenefits__card__description {
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  margin: 0;
}

.pixlmeBenefits__card__button {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  text-decoration: underline;
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
}

.pixlmeBenefits__card__right {
  width: 35%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pixlmeBenefits__card__right_image {
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 30px;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
  .pixlmeBenefits__section {
    width: 100vw;
    padding: 0px 0;
    justify-content: center;
    align-items: center;
    background-color: black;
  }

  .pixlmeBenefits__card {
    flex-direction: column-reverse;
    width: 90vw;
    padding: 20px 0;
    margin: 10px 0;
    border-radius: 16px;
  }

  .pixlmeBenefits__card__left {
    width: 80vw;
  }

  .pixlmeBenefits__card__title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    margin-top: 25px;
  }

  .pixlmeBenefits__card__description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    margin-top: 10px;
  }

  .pixlmeBenefits-appstore-buttons {
    display: none;
  }

  .pixlmeBenefits__card__button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
  }

  .pixlmeBenefits__card__right {
    height: auto;
    width: 85vw;
    border-radius: 16px;
  }
}

/* Responsive titles */
.pixlmeBenefits__title {
  font-family: Inter;
  font-size: 48px;
  font-weight: 600;
  line-height: 70px;
  text-align: left;
  color: white;
}

@media (min-width: 1600px) {
  .pixlmeBenefits__title {
    font-size: 2.8vw;
    line-height: 3.2vw;
  }
}

@media (min-width: 1920px) {
  .pixlmeBenefits__title {
    font-size: 2.5vw;
    line-height: 3vw;
  }
}

@media screen and (max-width: 700px) {
  .pixlmeBenefits__title {
    font-family: Inter;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    text-align: center;
    margin-top: 30px;
  }
}
